<template>
  <BaseCard
    title="Packages and Components"
    :step-component="'PackageSelectionStep'"
    class="mb-5"
    :edit="edit"
  >
    <div class="review">
      <div v-if="test_packages.length">
        {{ packageLabel }}:
        <span>{{ packageTitles }}</span>
      </div>
      <div v-if="is_calibrate">
        Calibrate:
        <ul>
          <li v-if="name">
            Name: <span>{{ name }}</span>
          </li>
          <li v-if="herd">
            Herd: <span>{{ herd }}</span>
          </li>
          <li v-if="calibrate_number">
            Calibrate Number:
            <span>{{ calibrate_number }}</span>
          </li>
          <li v-if="sample_calibrates_ingredients_id">
            Ingredient:
            <span>
              {{
                (
                  getById(
                    "sample_calibrates_ingredients",
                    sample_calibrates_ingredients_id
                  ) || {}
                ).title
              }}</span
            >
          </li>
          <li v-if="last_cut_days">
            Days Since Last Cut:
            <span>{{ last_cut_days }}</span>
          </li>
          <li v-if="cutting">
            Cutting: <span>{{ cutting }}</span>
          </li>
        </ul>
      </div>
      <div v-if="filtered_test_components.length">
        Test Components: <span>{{ componentTitles }}</span>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/newSamples/ReviewStepCards/BaseCard";
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapState } from "vuex";

export default {
  name: "PackagesAndComponents",
  components: { BaseCard },
  props: ["edit"],
  computed: {
    ...mapFields("samples", [
      "active_sample.test_packages",
      "active_sample.is_calibrate",
      "active_sample.sample_calibrate.name",
      "active_sample.sample_calibrate.herd",
      "active_sample.sample_calibrate.calibrate_number",
      "active_sample.sample_calibrate.last_cut_days",
      "active_sample.sample_calibrate.cutting",
      "active_sample.sample_calibrate.sample_calibrates_ingredients_id"
    ]),
    ...mapState("samples", ["active_sample"]),
    ...mapGetters("baseData", ["getById", "test_components"]),
    ...mapGetters("baseData", { allPackages: "test_packages" }),
    packageLabel() {
      return this.test_packages.length > 1 ? "Packages" : "Package";
    },
    packageTitles() {
      let titles = [];

      if (this.test_packages.length > 0) {
        titles.push(
          (this.getById("test_packages", this.test_packages[0].id) || {}).title
        );
      }

      if (this.test_packages.length > 1) {
        this.test_packages.slice(1).forEach(test_package => {
          titles.push(
            (this.getById("test_packages", test_package.id) || {}).title
          );
        });
      }

      return titles.join(", ");
    },
    filtered_test_components() {
      let ids = this.active_sample.test_components.map(item => item.id);
      if (
        this.active_sample.test_packages &&
        this.active_sample.test_packages.length
      ) {
        const packageComponents = this.allPackages.find(
          item => item.id === this.active_sample.test_packages[0].id
        ).test_components;
        ids = [
          // Include initial ids
          ...ids,
          // Add package component ids
          ...packageComponents.map(item => item.id)
        ];
      }
      // Return the filtered list of components included in this sample
      return this.test_components.filter(item => ids.indexOf(item.id) !== -1);
    },
    componentTitles() {
      return this.filtered_test_components
        .map(component => {
          return component.title;
        })
        .join(", ");
    }
  }
};
</script>

<style scoped></style>
