<template>
  <SampleView />
</template>

<script>
import SampleView from "@/components/newSamples/SampleView";
export default {
  name: "View",
  components: { SampleView }
};
</script>
