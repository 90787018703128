<template>
  <BaseCard title="Billing Information" class="mb-5" :edit="false">
    <div class="review">
      Business Name: <span>{{ billingAccount.title }}</span>
      <br />
      Name:
      <span>
        {{ billingAccount.first_name }} {{ billingAccount.last_name }}
      </span>
      <br />
      Address: <span>{{ billingAccount.address_1 }}</span>
      <br />
      <div v-if="billingAccount.address_2">
        Address 2: <span>{{ billingAccount.address_2 }}</span>
        <br />
      </div>
      City: <span>{{ billingAccount.city }}</span>
      <br />
      <div v-if="billingAccount.state_id">
        State, Region or Province:
        <span>{{
          (getById("states", billingAccount.state_id) || {}).title
        }}</span>
        <br />
      </div>
      Country:
      <span>{{
        (getById("states", billingAccount.country_id) || {}).title
      }}</span>
      <br />
      Postal Code: <span>{{ billingAccount.postal_code }}</span>
      <br />
      Phone Number: <span>{{ billingAccount.work_phone }}</span>
      <br />
      <div v-if="firstEmail">
        Email:
        <span>{{ firstEmail }}</span>
      </div>
      <!-- <div
        v-if="
          active_sample && active_sample.copy_to && active_sample.copy_to.length
        "
      >
        Report Recipients:
        <ul class="py-2">
          <li
            v-for="item in active_sample.copy_to"
            :key="item.id"
            class="font-weight-regular"
          >
            {{
              (copyToById(item.id, "copy_to_id") || {}).copy_to.email || "N/A"
            }}
          </li>
        </ul>
      </div> -->
    </div>
    <v-row dense style="margin-top: 20px;">
      <v-col>
        <v-alert border="top" colored-border type="info" elevation="2">
          If this billing information is not correct,
          <a :href="mailTo">click here</a> to send e-mail to:
          office@foragelab.com
        </v-alert>
      </v-col>
    </v-row>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/newSamples/ReviewStepCards/BaseCard";
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import isString from "lodash.isstring";

export default {
  name: "BillingInformation",
  components: { BaseCard },
  props: ["edit"],
  computed: {
    ...mapState("samples", ["billingAccount", "active_sample"]),
    ...mapGetters("samples", ["firstEmail"]),
    ...mapGetters("baseData", ["getById"]),
    ...mapGetters("copyTo", { copyToById: "getById" }),
    ...mapGetters("clients", ["selectedClient"]),
    mailTo() {
      return (
        "mailto:office@foragelab.com?subject=The billing information for account #" +
        this.billingAccount.clients_email_addresses[0].client_id +
        " is incorrect.  Please verify the information."
      );
    },
    shipment_id: {
      get() {
        const samples = this.$store.state.samples;
        if (
          !samples.active_sample.shipments ||
          !samples.active_sample.shipments.length
        )
          return null;
        return this.$store.state.samples.active_sample.shipments[0].id;
      },
      set(val) {
        this.updateActiveSample({ shipments: [{ id: val }] });
      }
    }
  },
  methods: {
    ...mapActions("samples", ["updateSampleSet"]),
    ...mapActions("samples", ["getBillingInformation"]),
    ...mapMutations("sampleWizard", ["setError"]),
    ...mapMutations("samples", ["updateActiveSample"]),
    beforeContinue() {
      return this.updateSampleSet("billing").then(res => {
        return !!res;
      });
    }
  },
  mounted() {
    this.loading = true;
    this.getBillingInformation()
      .then(() => {
        this.billingAgree = true;
        this.loading = false;
      })
      .catch(e => {
        // If an error is a string, set it as the error message for the wizard
        this.setError(isString(e) ? e : "An unknown error occurred.");
        // Log to console if this was a non-string error
        if (!isString(e)) console.error(e);
      });
  }
};
</script>
