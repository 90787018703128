<template>
  <v-card :flat="flat !== undefined || flat === true">
    <v-app-bar v-if="!!title" flat color="transparent">
      <v-toolbar-side-icon name="side-icon">
        <v-img
          v-if="title === 'Lab'"
          src="../../../assets/lab-icon.svg"
          alt="icon"
          style="width: 100%; margin-right: 10px; width: 22px;"
        />
        <v-img
          v-if="title === 'Packages and Components'"
          src="../../../assets/packages-icon.svg"
          alt="icon"
          style="width: 100%; margin-right: 10px; width: 22px;"
        />
        <v-img
          v-if="title === 'General Sample Information'"
          src="../../../assets/sample-info-icon.svg"
          alt="icon"
          style="width: 100%; margin-right: 10px; width: 22px;"
        />
        <v-img
          v-if="title === 'Billing Information'"
          src="../../../assets/billing-icon.svg"
          alt="icon"
          style="width: 100%; margin-right: 10px; width: 22px;"
        />
      </v-toolbar-side-icon>
      <v-toolbar-title class="title" style="margin-left: 5px;">
        {{ title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn v-if="edit !== false" icon @click.stop="goToStep">
        <v-icon>{{ editIcon || "mdi-square-edit-outline" }}</v-icon>
      </v-btn>
    </v-app-bar>
    <v-divider></v-divider>
    <v-container>
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  name: "BaseCard",
  props: ["title", "edit", "editIcon", "stepComponent", "flat"],
  computed: {
    ...mapGetters("sampleWizard", {
      getStepId: "newStepId"
    }),
    ...mapFields("sampleWizard", ["wizard.currentStep", "wizard.returnStep"]),
    newStepId() {
      return this.getStepId(this.stepComponent) + 1;
    }
  },
  methods: {
    goToStep() {
      this.returnStep = this.currentStep; // Return to currently activated step when we're done editing
      this.currentStep = parseInt(this.newStepId); // Go to the step defined in the prop, and make sure it's a number
    }
  },
  data() {
    return {
      imageData: "../../../assets/lab-icon.svg"
    };
  }
};
</script>

<style>
.review {
  font-weight: bold;
}

.review span {
  font-weight: normal;
}
</style>
