<template>
  <StepComponent>
    <LoadingBar
      v-if="loading === true || error.message.length"
      :error="error.message"
    />
    <div v-else>
      <v-row style="padding: 1rem;">
        <v-col>
          <LabSelection :edit="false" />
          <GeneralSampleInformation :edit="false" />
          <PackagesAndComponents :edit="false" />
        </v-col>
        <v-col>
          <BillingInformation :edit="false" />
          <Price />
        </v-col>
      </v-row>
      <v-row
        style="display: flex; margin: auto; margin-top: 2rem; padding: 0 1rem;"
        class="img-container"
      >
        <template>
          <SampleImages :sample-id="active_sample.slug" :read-only="true" />
        </template>
      </v-row>
    </div>
    <v-row
      style="display: flex; margin: auto; margin-top: 2rem;"
      class="img-container"
    >
    </v-row>
  </StepComponent>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";
//   import PackageSelectionStep from "@/components/newSamples/SampleWizardSteps/PackageSelectionStep";
//   import BillingStep from "@/components/newSamples/SampleWizardSteps/BillingStep";
//   import SampleInformationStep from "@/components/newSamples/SampleWizardSteps/SampleInformationStep";
//   import ReviewStep from "@/components/newSamples/SampleWizardSteps/ReviewStep";
//   import ShipmentSelectionStep from "@/components/newSamples/SampleWizardSteps/ShipmentSelectionStep";
//   import ImagingStep from "@/components/newSamples/SampleWizardSteps/ImagingStep";
import { mapActions, mapMutations, mapState } from "vuex";
import hasIn from "lodash.hasin";
//   import VeStepper from "@/components/VeStepper";
//   import VeStepperStep from "@/components/VeStepper/VeStepperStep";

import LabSelection from "@/components/newSamples/ReviewStepCards/LabSelection";
import BillingInformation from "@/components/newSamples/ReviewStepCards/BillingInformation";
import GeneralSampleInformation from "@/components/newSamples/ReviewStepCards/GeneralSampleInformation";
// import SamplingInformation from "@/components/newSamples/ReviewStepCards/SamplingInformation";
import PackagesAndComponents from "@/components/newSamples/ReviewStepCards/PackagesAndComponents";
// import ReviewImages from "@/components/samples/ReviewStepCards/ReviewImages";
import Price from "@/components/newSamples/ReviewStepCards/Price";
import SampleImages from "@/components/newSamples/components/SampleImages";
import StepComponent from "@/components/newSamples/SampleWizardSteps/StepComponent";

/**
 * @property {int} currentStep
 */
export default {
  name: "SampleView",
  components: {
    LoadingBar,
    //   PackageSelectionStep,
    //   BillingStep,
    //   SampleInformationStep,
    //   ReviewStep,
    //   ShipmentSelectionStep,
    //   ImagingStep,
    //   VeStepper,
    //   VeStepperStep

    LabSelection,
    BillingInformation,
    GeneralSampleInformation,
    // SamplingInformation,
    PackagesAndComponents,
    Price,
    SampleImages,
    StepComponent
  },
  data() {
    return {
      loading: false,
      refIdReminder: false,
      valid: false, // This gets updated automatically, via the validator
      halt: false // Allows a child component to halt continue by emitting updateHalt
    };
  },
  computed: {
    ...mapState("samples", ["active_sample"]),
    ...mapFields("sampleWizard", [
      "wizard.currentStep",
      "wizard.returnStep",
      "wizard.error"
    ]),
    ...mapFields("samples", ["billingAgree"]),
    // Returns the current step component instance
    currentStepComponent() {
      if (!this.$refs) return undefined;
      // Find the current component (by ref="componentName")
      // currentStep is ahead of the index by 1
      let component = this.$refs[this.currentNewStepComponentTitle];
      if (!component) return undefined;
      if (Array.isArray(component)) {
        // The component will most likely be in an array, but it'll only have one item in it
        component = component[0];
      }
      return component;
    },
    canContinue() {
      if (this.error.message.length) {
        return false;
      }
      return this.valid;
    },
    nextStepBtnLabel() {
      if (this.currentStep === this.newSteps.length) return "Finished";
      // else if (this.currentNewStepComponentTitle === "ReviewStep")
      //   return "Add to Shipment";
      return "Continue";
    },
    activeSampleShipmentId() {
      return this.active_sample && this.active_sample.shipment_id
        ? this.active_sample.shipment_id
        : this.active_sample.shipment
        ? this.active_sample.shipment.id
        : null;
    },
    routeSampleId() {
      return hasIn(this.$route, "params.sampleId")
        ? this.$route.params.sampleId
        : null;
    }
  },
  methods: {
    ...mapActions("samples", ["setActive"]),
    ...mapActions("baseData", [
      "getLabs",
      "getTestPackageCategories",
      "getPackages",
      "getComponentCategories",
      "getTestComponents",
      "getFeedTypes",
      "getFeedClasses",
      "getCalibrateIngredients"
    ]),
    ...mapActions(["notify"]),
    ...mapActions("farms", ["getFarms"]),
    ...mapMutations("samples", ["clearActive"]),
    ...mapMutations("sampleWizard", ["resetWizard"]),
    previousStep() {
      this.currentStep = this.currentStep - 1;
    },
    // Proceed to the next step
    nextStep(n, cb = undefined) {
      // If validation doesn't succeed, we can stop now
      if (!this.$refs.form.validate()) return false;
      if (this.currentNewStepComponentTitle === "ReviewStep")
        this.refIdReminder = !this.refIdReminder;
      if (this.refIdReminder) return false;
      // If component has beforeContinue method, execute it. Otherwise, we can safely set to true.
      const beforeContinue =
        this.currentStepComponent && this.currentStepComponent.beforeContinue
          ? this.currentStepComponent.beforeContinue()
          : true;
      // Resolve as a promise, that way we can do async things when needed
      Promise.resolve(beforeContinue).then(res => {
        // As long as we don't have a false response, we can proceed to the next step
        if (res) {
          // If returnStep contains a step, go to that step
          if (this.returnStep && typeof this.returnStep === "number") {
            this.currentStep = this.returnStep;
            this.returnStep = false; // Reset returnStep value
            return true;
          }
          // Default to next step iteration
          this.currentStep = n + 1;

          if (this.currentStep > this.newSteps.length) {
            // const shipmentId = this.activeSampleShipmentId;
            // Sample submitted. Clear active_sample and reset wizard
            this.clearActive();
            this.resetWizard();
            // Only issue redirect if a callback wasn't defined
            if (typeof cb !== "function") {
              let message = "Sample Data Added Successfully";
              this.$router.push({
                path: "/samples/newbrowse"
              });
              this.notify({ message, route: "/samples/new-sample-wizard" });
            }
          }
          // execute the callback if one was provided
          if (typeof cb === "function") cb();
        }
      });
    },
    saveAndExit(step) {
      this.nextStep(step, () => {
        this.clearActive();
        this.resetWizard();
        this.$router.go(-1);
      });
    },
    addAnotherSample() {
      this.refIdReminder = true;
      // this.nextStep(this.newSteps.length, () => {
      //   this.setActive(null);
      // });
      window.location.reload();
    }
  },
  mounted() {
    this.loading = true;
    this.error.message = "";

    if (this.routeSampleId) {
      this.billingAgree = true;
      localStorage.setItem("stored_step", this.routeSampleId);
      if (this.routeSampleId !== localStorage.getItem("stored_step")) {
        this.currentStep = 1;
      }
    } else {
      this.currentStep = 1;
    }

    Promise.all([
      this.getLabs(),
      this.getTestPackageCategories(),
      this.getPackages(),
      this.getComponentCategories(),
      this.getTestComponents(),
      this.getFarms(),
      this.getFeedTypes(),
      this.getFeedClasses(),
      this.getCalibrateIngredients(),
      // Initialize active sample
      this.setActive(this.routeSampleId)
    ]).then(data => {
      // Check for errors from any promise
      if (data.includes(false)) {
        this.error.message = "An error occurred while retrieving this sample";
      }
      this.loading = false;
    });

    // this.active_sample.lab_id =
    //   this.active_sample.shipment && this.active_sample.shipment.lab_id
    //     ? this.active_sample.shipment.lab_id
    //     : null;
  }
};
</script>
